@use '@angular/material' as mat;

@mixin forms-mixin($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);

  .cus-input {
    background: mat.get-color-from-palette($background, hover);
    border: 2px solid transparent;
    outline: none;
    font-size: 14px;
    opacity: .8;
    color: inherit;
    opacity: .7;
    height: unset;
    padding: 6px 10px;
    border-radius: 5px;

    &.dark {
      background: mat.get-color-from-palette($background, status-bar, .5);
    }

    &:hover {
      background: mat.get-color-from-palette($background, hover);
      opacity: 1;
    }

    &:focus,
    &[aria-owns] {
      background: mat.get-color-from-palette($background, hover);
      border-color: mat.get-color-from-palette($primary);
      opacity: 1;
    }
  }

  .owl-dt-container {
    background: mat.get-color-from-palette($background, app-bar);
    font-size: 1.3rem;
    color: mat.get-color-from-palette($foreground, base, 0.6);

    .owl-dt-timer-content {
      .owl-dt-timer-input {
        background: mat.get-color-from-palette($background, hover);
        border: 2px solid transparent;
        color: inherit;

        &:hover {
          background: mat.get-color-from-palette($background, hover);
          opacity: 1;
        }

        &:focus {
          background: mat.get-color-from-palette($background, hover);
          border-color: mat.get-color-from-palette($primary);
          opacity: 1;
        }
      }
    }

    .owl-dt-container-buttons {
      height: unset;

      button {
        span {
          font-size: 1.35rem;
          padding: 9px 10px;
        }
      }
    }

    button {
      min-height: unset;
    }
  }

  .mat-slide-toggle {
    &.green {
      &.mat-checked:not(.mat-disabled) {
        .mat-slide-toggle-thumb {
          background-color: #03bc6d;
        }

        .mat-slide-toggle-bar {
          background-color: #18ae6e;
        }
      }
    }
  }

  .cus-number-input {

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    -moz-appearance: textfield;
  }

  mat-radio-button {
    margin-right: 20px;
  }


  .select-search {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 10px;
    margin: 15px 10px;

    .search-icon {
      position: absolute;
      left: 1.2rem;
      font-size: 2rem;
      height: 1.9rem;
      width: 2rem;
      opacity: 0.2;
    }

    input {
      border-radius: 0.7rem;
      border: none;
      padding: 5px 10px 5px 40px;
      height: 45px;
      width: 100%;
      outline: none;
    }

    .close {
      top: 5px;
      right: 2px;
      position: absolute;
    }
  }

}

// @mixin Forms Ends