@mixin snackbar-mixin() {
    .success-snackbar,
    .error-snackbar,
    .info-snackbar{
        background: #292929 !important;
        color: whitesmoke !important;
        position: relative;
        overflow: hidden;
        &::before{
            content: "";
            width: 140px;
            height: 90px;
            border-radius: 50%;
            background: #01dc6a;
            position: absolute;
            left: -40px;
            z-index: -1;
            top: -10px;
            filter: blur(56px);
        }
    }
    .success-snackbar {
        &::before{
            background: #01dc6a;
        }
        .mat-icon{
            color: #01dc6a;
        }
    }
    .error-snackbar {
        &::before{
            background: #fe3451;
        }
        .mat-icon{
            color: #fe3451;
        }
    }
    .info-snackbar {
        &::before{
            background: #ffcc00;
        }
        .mat-icon{
            color: #ffcc00;
        }
    }
}