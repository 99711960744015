/////////////////////////////
// Code Sample             //
/////////////////////////////

// <div class="dialog-wrapper">
// <h3>Heading....</h3>
// <a mat-icon-button (click)="onNoClick()" class="close">
//     <mat-icon class="material-icons-outlined">close</mat-icon>
// </a>
// <div class="dialog-content">
//     <!-- content goes here -->
// </div>
// <div class="dialog-footer">
//     <!-- button goes here -->
// </div>
// </div>

@mixin dialog-mixin($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  .bg-card {
    .mat-dialog-container {
      background: transparent;
    }
  }
  .dialog-wrapper {
    margin: 0;
    max-width: 100%;
    // max-width: 500px;
    border-radius: 10px;
    position: relative;
    .close {
      position: absolute;
      right: -5px;
      top: -5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .formlbl{
      opacity: .5;
      margin-bottom: 1rem;
    }
    .dialog-content {
      .mat-form-field-wrapper {
        padding: 5px 0 10px;
      }
    }
    .dialog-footer {
      padding-top: 1rem;
      button:not(:last-child) {
        margin-right: 1.5rem;
      }
    }
  }
  .mat-dialog-actions {
    margin-bottom: -14px;
  }
} // @mixin dialog Ends
