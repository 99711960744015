@use '@angular/material' as mat;
@mixin expansion-panel-mixin($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  
  
  .expansionPanel{
    .mat-expansion-panel-header-title,
    .mat-expansion-panel-header-description {
      font-size: 1.4rem;
      flex-basis: 0;
      // justify-content: space-between;
    }
    .mat-expansion-panel{
      box-shadow: 0 0 transparent;
      border: 1px solid mat.get-color-from-palette($foreground, base, 0.3);
      border-bottom-width: 0;
      border-radius: 0px 0px 0px 0px;
      margin: 0;
      background: transparent;
      &:last-child{
        border-bottom-width: 1px;
      }
    }
    .mat-expansion-panel-header{
      padding:10px 15px;
      height: auto;
    }
    .mat-expansion-panel-body{
      padding: 0;
    }
    .content-wrapper{
      background:  mat.get-color-from-palette($background, hover);
      padding: 15px;
      &.no-bg{
        background: transparent;
      }
    }


    &.has-childs{   
      .mat-expansion-panel-header{
        padding: 8px 15px 8px 5px;
        .panel-header{
          width: 100%;
          display: flex;
          align-items: center;
          .title{
            width: 65%;
          }
          .actions{
              margin-left: auto;
              position: relative;
              display: flex;
              align-items: center;
              .status{
                width: 55px;
                margin-right: 12px;
                .mat-chip-list-wrapper{
                  justify-content: flex-end;
                }
              }
              .btns{
                display: flex;
                align-items: center;
                opacity: 0;
                visibility: hidden;
                transform: translateX(-10px);
                transition: .5s ease all;
              }
          }
          &:hover{
              .actions{
                  .btns{
                    opacity: 1;
                    visibility: visible;
                    transform: none;
                  }
              }
          }
        }
      }
      .content-wrapper{
        padding-top: 0;
        h6{
          opacity: .5;
          margin-bottom: 5px;
        }
        .description{
          app-editable-field {
            display: block;
            .editable-field{
              font-size: 1.2rem;
              color: mat.get-color-from-palette($foreground, base, 0.6);
            }
          }
        }
        .mat-divider{
          margin: 10px 0 15px;
        }
        .child{
          .item{
            position: relative;
            padding-left: 20px;
            width: 100%;
            display: flex;
            align-items: center;
            &::before{
              content: '';
              position: absolute;
              left: 2px;
              top: 50%;
              transform: translateY(-50%);
              background: mat.get-color-from-palette($foreground, base, 0.5);
              width: 8px;
              height: 8px;
              border-radius: 50%;
            }
            .title{
              width: 65%;
            }
            .actions{
              margin-left: auto;
              position: relative;
              display: flex;
              align-items: center;
              .status{
                width: 55px;
                .mat-chip-list-wrapper{
                  justify-content: flex-end;
                }
              }
              .btns{
                display: flex;
                align-items: center;
                opacity: 0;
                visibility: hidden;
                transform: translateX(-10px);
                transition: .5s ease all;
              }
            }
            &:hover{
              .actions{
                .btns{
                  opacity: 1;
                  visibility: visible;
                  transform: none;
                }
              }
            }
            &.inactive{
              .title{
                opacity: .5;
              }
              &::before{
                opacity: .5;
              }
            }
            &.add{
              padding-left: 0;
              &::before{
                display: none;
              }
              .add-input{
                // position: absolute;
                width: 100%;
                z-index: 1;
                .input-wrapper{
                  .addInput{
                    background: mat.get-color-from-palette($background, hover);
                    color: mat.get-color-from-palette($foreground, base, 0.3);
                  }
                }
                &.dark-field{
                  .input-wrapper{
                    .addInput{
                      background: mat.get-color-from-palette($background, card, .5);
                      // color: mat-color($foreground, base, 0.3);
                    }
                  }

                }
              }
              button{
                background: transparent;
                border: none;
                outline: none;
                display: flex;
                justify-content: center;
                align-items: center;
                color: mat.get-color-from-palette($foreground, base);
                opacity: 0.45;
                padding: 0;
                transition: .3s ease all;
                .mat-icon{
                  font-size: 19px;
                  width: 19px;
                  height: 19px;
                  margin-right: 6px;
                }
                &:hover{
                  opacity: .8;
                }
              }
            }
          }
        }
      }
    }
  }
  
  @media(max-width:767px){
    .expansionPanel{
      &.has-childs{   
        .mat-expansion-panel-header{
          .panel-header{
            flex-direction: column;
            .title{
              width: 100%;
            }
            .actions{
              width: 100%;
              flex-direction: row-reverse;
              height: 23px;
              margin-left: 20px;
              justify-content: flex-end;
              .btns{
                opacity: 1;
                visibility: visible;
                transform: scale(0.8) translateX(0);
                margin-left: 0;
              }
              .status{
                margin-right: 0;
                transform: scale(0.8) translateX(-5px);
                .mat-chip-list-wrapper{
                  justify-content: flex-start;
                }
              }
            }
            &:hover{
              .actions {
                .btns {
                  transform: scale(0.8) translateX(0);
                }
              }
            }
          }
        }
        .content-wrapper{
          .child{
            .item{
              .title{
                width: 65%;
                app-editable-field{
                  .editable-field {
                    padding: 6px 6px;
                    font-size: 13px;
                  }
                }
              }
              .actions{
                max-width: 130px;
                margin-right: -12px;
                .status{
                  transform: translateX(0);
                  width: 40px;
                  .mat-chip-list-wrapper{
                    .mat-chip.small{
                      transform: scale(0.8) translateX(0);
                    }
                  }
                }
                .btns{
                  opacity: 1;
                  visibility: visible;
                  transform: scale(0.8) translateX(0);
                }
              }
              &:hover{
                .actions{
                  .btns{
                    transform: scale(0.8) translateX(0);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

} // @mixin expansion-panel Ends