@use '@angular/material' as mat;
@import "mixins";
@mixin stepper-mixin($theme) {
    $primary: map-get($theme, primary);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

     
    .custom-stepper {
        .step {
            @include display-flex(flex-start, flex-start);
            height: 100%;
            
            .indicator {
                @include display-flex(center, space-between);
                align-self: stretch;
                flex-direction: column;
                .status-indicator {
                    @include circle(40px);
                    @include display-flex;
                    @include font-size(20, 20, 500);
                    border: 2px solid transparent;
                    padding: 8px;
                    user-select: none;

                    color: mat.get-color-from-palette($foreground, text, .3);
                    background-color: mat.get-color-from-palette($background, background, 0.1);
                    border-color: mat.get-color-from-palette($foreground, text, .3);

                    .mat-icon{
                        // color:mat-color($foreground, text, .8);
                        color:rgba(255, 255, 255, 0.8);
                    }
                }
                .connector {
                    width: 2px;
                    height: 100%;
                    background-color: mat.get-color-from-palette($foreground, text, .3);
                }
            }
            .details {
                @include display-flex(flex-start, center);
                flex-direction: column;
                min-height: 40px;
                margin-left: 16px;
                margin-bottom: 28px;
                opacity: .5;
                width: 100%;
                max-width: calc(100% - 60px);
                h4{
                    opacity: .8;
                }
            }
            &:last-child{
                .connector{
                    display: none;
                }
                .details {
                    margin-bottom: 0;
                }
            } 

            
            &.active{
                .indicator{
                    .status-indicator{
                        color: mat.get-color-from-palette($primary);
                        background-color: mat.get-color-from-palette($primary, 500, .1);
                        border-color: mat.get-color-from-palette($primary);
                    }
                    .connector{
                        background-color: mat.get-color-from-palette($foreground, text, .3);
                    }
                }
                .details{
                    opacity: 1;
                }
            }

            &.checked{
                .indicator{
                    .status-indicator{
                        background-color: mat.get-color-from-palette($primary);
                        border-color: mat.get-color-from-palette($primary);
                    }
                    .connector{
                        background-color: mat.get-color-from-palette($primary);
                    }
                }
                .details{
                    opacity: .7;
                }
            }
        }
    }
  

    @media(max-width:767px){
        
    }


} // @mixin stepper Ends