// Custom Theming for Angular Material
@use "@angular/material" as mat;
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";
@import "@angular/cdk/overlay-prebuilt.css";
// Plus imports for other components in your app.

// Custom Mixins of Partials
@import "assets/css/includes";
@import "assets/css/theme/color";

//External Css
@import "~nouislider/distribute/nouislider.min.css";

// $typography-config: mat-typography-config(
// //   $font-family: "Cera Pro",
//   $body-1: mat-typography-level(10px, 1.5, 400),  /* Base body text */
//   $body-2: mat-typography-level(10px, 1.5, 600),  /* Bolder body text. */
//   $headline: mat-typography-level(3.6rem, 1.2, 700), /* h1 */
//   $title: mat-typography-level(3.2rem, 1.2, 600), /* h2 */
//   $subheading-2: mat-typography-level(2.5rem, 1.2, 500), /* h3 */
//   $subheading-1: mat-typography-level(2rem, 1.2, 500), /* h4 */
// );

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$theme-primary: mat.define-palette(mat.$blue-palette, A700);
$theme-accent: mat.define-palette(mat.$green-palette, A400);

// The warn palette is optional (defaults to red).
$theme-warn: mat.define-palette(mat.$red-palette, A400);

@mixin darkCss {
  ngx-skeleton-loader .loader {
    border-radius: 5px;
    background-color: #323232;
    border: 1px solid #323232;
    animation-duration: 2s;
    &.progress {
      &::before {
        background-image: linear-gradient(
          90deg,
          hsl(0deg 0% 0% / 0%),
          hsl(0deg 0% 0% / 13%),
          hsl(0deg 0% 0% / 0%)
        );
      }
    }
  }
}

@mixin lightCss {
  ngx-skeleton-loader .loader {
    background: #e2e2e2;
    border-radius: 5px;
    animation-duration: 2s;
  }
}
// // For Dialog Starts
// $theme: mat.define-light-theme($theme-primary, $theme-accent, $theme-warn);
// @include mat.all-component-themes($theme);
// @include includes-mixin($theme);
// // For Dialog Ends

.dark-theme {
  $theme: mat.define-dark-theme($theme-primary, $theme-accent, $theme-warn);
  $theme: get-modified-dark-theme($theme);
  @include mat.all-component-themes($theme);
  @include includes-mixin($theme);
  @include darkCss;
}

.light-theme {
  $theme: mat.define-light-theme($theme-primary, $theme-accent, $theme-warn);
  $theme: get-modified-light-theme($theme);
  @include mat.all-component-themes($theme);
  @include includes-mixin($theme);
  @include lightCss();
}

// Blue and Amber
$theme-blue-amber-primary: mat.define-palette(mat.$blue-palette, A700);
$theme-blue-amber-accent: mat.define-palette(mat.$amber-palette, A400);
.blue-amber-dark-theme {
  $theme: mat.define-dark-theme(
    $theme-blue-amber-primary,
    $theme-blue-amber-accent,
    $theme-warn
  );
  $theme: get-modified-dark-theme($theme);
  @include mat.all-component-themes($theme);
  @include includes-mixin($theme);
  @include darkCss;
}
.blue-amber-light-theme {
  $theme: mat.define-light-theme(
    $theme-blue-amber-primary,
    $theme-blue-amber-accent,
    $theme-warn
  );
  $theme: get-modified-light-theme($theme);
  @include mat.all-component-themes($theme);
  @include includes-mixin($theme);
  @include lightCss;
}
// Indigo Pink
$theme-indigo-pink-primary: mat.define-palette(mat.$indigo-palette, 500);
$theme-indigo-pink-accent: mat.define-palette(mat.$pink-palette, A400);

.indigo-pink-dark-theme {
  $theme: mat.define-dark-theme(
    $theme-indigo-pink-primary,
    $theme-indigo-pink-accent,
    $theme-warn
  );
  $theme: get-modified-dark-theme($theme);
  @include mat.all-component-themes($theme);
  @include includes-mixin($theme);
  @include darkCss;
}
.indigo-pink-light-theme {
  $theme: mat.define-light-theme(
    $theme-indigo-pink-primary,
    $theme-indigo-pink-accent,
    $theme-warn
  );
  $theme: get-modified-light-theme($theme);
  @include mat.all-component-themes($theme);
  @include includes-mixin($theme);
  @include lightCss;
}

// Pink & Blue Gray
$theme-pink-blue-gray-primary: mat.define-palette(mat.$pink-palette, 500);
$theme-pink-blue-gray-accent: mat.define-palette(mat.$blue-grey-palette, A400);

.pink-blue-gray-dark-theme {
  $theme: mat.define-dark-theme(
    $theme-pink-blue-gray-primary,
    $theme-pink-blue-gray-accent,
    $theme-warn
  );
  $theme: get-modified-dark-theme($theme);
  @include mat.all-component-themes($theme);
  @include includes-mixin($theme);
  @include darkCss;
}
.pink-blue-gray-light-theme {
  $theme: mat.define-light-theme(
    $theme-pink-blue-gray-primary,
    $theme-pink-blue-gray-accent,
    $theme-warn
  );
  $theme: get-modified-light-theme($theme);
  @include mat.all-component-themes($theme);
  @include includes-mixin($theme);
  @include lightCss;
}

// Purple & Green
$theme-purple-green-primary: mat.define-palette(mat.$purple-palette, 500);
$theme-purple-green-accent: mat.define-palette(mat.$green-palette, A400);

.purple-green-dark-theme {
  $theme: mat.define-dark-theme(
    $theme-purple-green-primary,
    $theme-purple-green-accent,
    $theme-warn
  );
  $theme: get-modified-dark-theme($theme);
  @include mat.all-component-themes($theme);
  @include includes-mixin($theme);
  @include darkCss;
}
.purple-green-light-theme {
  $theme: mat.define-light-theme(
    $theme-purple-green-primary,
    $theme-purple-green-accent,
    $theme-warn
  );
  $theme: get-modified-light-theme($theme);
  @include mat.all-component-themes($theme);
  @include includes-mixin($theme);
  @include lightCss;
}

// Green & Blue
$theme-green-blue-primary: mat.define-palette(mat.$green-palette, A400);
$theme-green-blue-accent: mat.define-palette(mat.$blue-palette, A700);

.green-blue-dark-theme {
  $theme: mat.define-dark-theme(
    $theme-green-blue-primary,
    $theme-green-blue-accent,
    $theme-warn
  );
  $theme: get-modified-dark-theme($theme);
  @include mat.all-component-themes($theme);
  @include includes-mixin($theme);
  @include darkCss;
}
.green-blue-light-theme {
  $theme: mat.define-light-theme(
    $theme-green-blue-primary,
    $theme-green-blue-accent,
    $theme-warn
  );
  $theme: get-modified-light-theme($theme);
  @include mat.all-component-themes($theme);
  @include includes-mixin($theme);
  @include lightCss;
}

/* You can add global styles to this file, and also import other style files */

:root {
  font-size: 10px;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  -webkit-print-color-adjust: exact;
}

.font-montserrat {
  font-family: "Montserrat", sans-serif;
}

.mat-drawer-container {
  min-height: 100vh;
}

.info-card {
  color: #737373 !important;
  padding: 8px !important;
  .mat-card-subtitle {
    color: #737373 !important;
  }
  .mat-card-content {
    color: #737373 !important;
  }
}

.gm-style .gm-style-iw-d {
  max-width: 350px !important;
  padding: 0 !important;
}

/*
::Table Of Content

1.) @FontFace Style
2.) Global TypoGraphy
3.) Global Links, Utilities & Buttons
4.) Global Sprite Icon Style
5.) Global Utilities
6.) Grid System Starts
7.) Site Wide Content
8.) Global Form Elements
9.) Animations
10.) Page global style
11.) Responsive style
-------------------------------------*/

/*
1.) @FontFace Style
-------------------------------------*/

// .material-icons {
//     font-size: 21px;
// }

/*
7.) Site Wide Content
-------------------------------------*/

#wrapper {
  position: relative;
}

.container {
  width: 100%;
  max-width: 1048px;
  margin: 0 auto;
  padding: 0 25px;
}

.absolute-content {
  position: absolute;
  width: 100%;
  left: 0;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}


/*
8.) Global Form Elements
-------------------------------------*/

.mat-option strong {
  font-weight: 500;
}

/*
9.) Animations
-------------------------------------*/
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes rotatecircle {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}

@keyframes rotatecircle {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

/*
10.) Page global style
-------------------------------------*/

.page-header {
  position: relative;
  text-align: center;
  width: 100%;
  margin-bottom: 80px;
  /*margin-bottom: 44px;*/
  .mat-button {
    position: absolute;
    left: -25px;
  }
}

/*
11.) Responsive style
-------------------------------------*/

@media (max-width: 1024px) {
  .page-header {
    margin-bottom: 58px;
  }
  .page-header .mat-button {
    left: 0;
  }
}
@media (max-width: 950px) {
}
@media (max-width: 876px) {
}
@media (max-width: 767px) {
  .mat-typography {
    h1,
    h2 {
    }
    h3 {
    }
    h4 {
    }
    p {
    }
  }
  .dshow {
    display: block !important;
  }
  .dhide {
    display: none !important;
  }
  .container {
    padding: 0 20px;
  }
}
