@use '@angular/material' as mat;

@mixin common-mixin($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);


  .authForm-wrapper {
    text-align: center;

    figure {
      &.img-icon {
        max-width: 180px;
        margin: 0 auto 3rem;
      }

      &.restaurant-logo {
        // background-color: mat.get-color-from-palette($background, card);
        background: linear-gradient(45deg, #bdbdbd, #ebebeb);
        box-shadow: 0 0 15px rgb(0 0 0 / 10%);
        border-radius: 50%;
        width: 200px;
        height: 200px;
        margin: 0 auto 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        img {
          max-width: 130px;
          margin-bottom: 0;
        }
      }

      img {
        height: auto;
      }
    }

    .secondary-action {
      text-align: center;
      color: mat.get-color-from-palette($foreground, text, .5);

      a {
        cursor: pointer;
      }
    }

    .numPrefix {
      opacity: .7;
      bottom: 3px;
      position: relative;
    }
  }



  .topping-group {
    border-radius: 8px;
    border-style: dashed;
    margin-bottom: 1.8rem;

    .tg-header {
      display: flex;
      align-items: center;
      padding: .5rem 1.2rem;

      h3 {
        font-size: 1.6rem;
        flex: 1;
        margin: 7px 0;
      }

      mat-slide-toggle {
        margin-left: .5rem;
      }

      .mat-divider {
        height: 2rem;
        margin: 0 1rem;
      }

      .btn-sml {
        border-radius: 8px;
        min-height: unset;
      }
    }

    .dash-box {
      max-width: 60%;
      margin: 1rem auto 2rem;
    }

    .topping-list {
      border-radius: 8px;
      margin: 1.2rem;
      overflow-x: auto;

      table {
        width: 100%;
        font-size: 1.2rem;

        th {
          padding: 0.5rem 1rem;
          font-weight: 400;
          opacity: .5;

          &:first-child {
            min-width: 100px;
            text-align: left;
            padding-left: 26px;
          }

          &:nth-child(4) {
            width: 110px;
          }

          &:nth-child(5) {
            width: 65px;
          }
        }

        td {
          text-align: center;
          padding: 0.5rem 1rem;

          >* {
            opacity: .8;
          }

          &:first-child {
            text-align: left;
            padding-left: 26px;
          }

          .cus-number-input {
            padding: 5px;
            max-width: 60px;
            border-radius: 6px;
            text-align: center;
            color: mat.get-color-from-palette($foreground, text, .5);
          }

          .status {
            .mat-chip-list-wrapper {
              justify-content: center;
            }
          }
        }
      }
    }
  }


  @media (max-width: 767px) {
    .topping-group {
      .tg-header {
        flex-wrap: wrap;

        h3 {
          width: 100%;
        }
      }
    }
  }

}

// common-mixin Ends