@use '@angular/material' as mat;

@mixin table-mixin($theme) {
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .expandTable {
    tr.table-row:not(.table-expanded-row):hover {
      background: mat.get-color-from-palette($background, status-bar, 0.3);
    }

    tr.table-row:not(.table-expanded-row):active {
      background: mat.get-color-from-palette($background, status-bar, 0.5);
    }

    tr.table-detail-row {
      height: 0;
    }

    .table-row td {
      border-bottom-width: 0;
    }

    .table-detail {
      overflow: hidden;
      display: flex;
    }
  }

  .multi-child-table {
    tr {
      td {
        border-bottom: 1px solid mat.get-color-from-palette($foreground, slider-off, .12);
      }
      
      &.hasChild {
        td {
          padding: 0 1rem !important;

          &:first-child {
            position: relative;
            padding-left: 26px !important;
            overflow: visible;

            &::before {
              content: "";
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              width: 9px;
              height: 9px;
              left: 6px;
              background-color: mat.get-color-from-palette($primary);
              opacity: 1;
              border-radius: 50%;
            }
          }
        }
      }

      &.nested {
        td {
          padding: 0 1rem !important;
          height: 40px;

          &:first-child {
            position: relative;
            padding-left: 42px !important;
            overflow: visible;

            &::before {
              content: "";
              position: absolute;
              top: -22px;
              width: 1px;
              height: calc(100% + 2px);
              right: auto;
              left: 9px;
              bottom: 0;
              border-left: 2px solid mat.get-color-from-palette($primary);
              opacity: 1;
            }

            &::after {
              content: '';
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              width: 25px;
              height: 2px;
              right: auto;
              left: 10px;
              border-top: 2px solid mat.get-color-from-palette($primary);
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .mat-elevation-z8 {
    overflow-y: hidden;
    overflow-x: auto;
    max-width: 100%;
  }

  .mat-table {
    width: 100%;
  }

  .mat-header-cell,
  .mat-cell {
    // min-width: 130px;
    padding: 0 10px;
    white-space: nowrap;
  }

  .mat-row,
  .mat-header-row {
    width: 100%;
  }

  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    padding: 10px 0;
  }

  th.mat-header-cell:first-of-type,
  td.mat-cell:first-of-type,
  td.mat-footer-cell:first-of-type {
    padding-left: 0;
  }

  th.mat-header-cell:last-of-type,
  td.mat-cell:last-of-type,
  td.mat-footer-cell:last-of-type {
    padding-right: 0;
  }

  th,
  td {
    overflow: hidden;
    width: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

// @mixin table Ends