@mixin typography-mixin() {
    
/*
2.) Global TypoGraphy
-------------------------------------*/
.mat-card,
.mat-typography,
.mat-tab-group,
.mat-expansion-panel-content,
.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
    font-size: 1.4rem;
    font-style: normal;
}

    h1,
    h2,
    h3,
    h4,
    h5 {
        font-style: normal;
        line-height: 1.2;
        font-weight: 700;
        margin-bottom: 1rem;
    }
    h1{
        font-size: 3.6rem;
    }
    h2 {
        font-size: 3.2rem;
        font-weight: 600;
    }
    h3 {
        font-size: 2.5rem;
        font-weight: 500;
    }
    h4 {
        font-size: 2rem;
        font-weight: 500;
    }
    h5 {
        font-size: 1.5rem;
        font-weight: 500;
    }
    h6 {
        font-size: 1.2rem;
        font-weight: 500;
    }
    p {
        opacity: 0.6;
        line-height: 1.4;
    }
    header{
        h1{
            margin-bottom: 3rem;
            small{
                display: block;
                font-size: 0.4em;
                padding-top: .6rem;
                font-weight: 400;
                letter-spacing: 0.14rem;
                opacity: .7;
            }
        }
    }

    @media(max-width: 900px){
        h1{
            font-size: 2.8rem;
        }
        h2 {
            font-size: 2.3rem;
        }
        h3 {
            font-size: 1.8rem;
        }
        h4 {
            font-size: 1.4rem;
        }
        h5 {
            font-size: 1.2rem;
        }
        h6 {
            font-size: 1.1rem;
        }
        header{
            h1{
                margin-bottom: 1.5rem;
            }
        }
    }


} // @mixin typography-mixin($theme) Ends