@mixin grid-mixin() {
.align-items-center{
    align-items: center;
}
.align-flex-start{
    align-items: flex-start;
}
.justify-content-center{
    justify-content: center;
}
.flex-dir-column{
    flex-direction: column;
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    &:before,
    &:after {
        content: "";
        display: table;
        clear: both;
    }
    &.no-gutters {
        margin-right: 0;
        margin-left: 0;
        > [class*=col-] {
            padding-right: 0;
            padding-left: 0
        }
    }
    &.even {
        flex-direction: row-reverse;
    }
    &.form-row{
        [class*="col-"]{
            padding: 0 10px;
        }
    }
    .col-1 {width: 8.33%;}
    .col-2 {width: 16.66%;}
    .col-3 {width: 25%;}
    .col-4 {width: 33.33%;}
    .col-5 {width: 41.66%;}
    .col-6 {width: 50%;}
    .col-7 {width: 58.33%;}
    .col-8 {width: 66.66%;}
    .col-9 {width: 75%;}
    .col-10 {width: 83.33%;}
    .col-11 {width: 91.66%;}
    .col-12 {width: 100%;}
    [class*="col-"] {
        padding: 10px;
        &.has-rows{
            margin: -10px 0;
            .row-1 {height: 8.33%;}
            .row-2 {height: 16.66%;}
            .row-3 {height: 25%;}
            .row-4 {height: 33.33%;}
            .row-5 {height: 41.66%;}
            .row-6 {height: 50%;}
            .row-7 {height: 58.33%;}
            .row-8 {height: 66.66%;}
            .row-9 {height: 75%;}
            .row-10 {height: 83.33%;}
            .row-11 {height: 91.66%;}
            .row-12 {height: 100%;}
            [class*="row-"] {
                padding: 10px 0;
            }
        }
    }
}
@media(max-width: 991px){
    .row{
        [class*="col-"] {
            &.md-1 {width: 8.33%;}
            &.md-2 {width: 16.66%;}
            &.md-3 {width: 25%;}
            &.md-4 {width: 33.33%;}
            &.md-5 {width: 41.66%;}
            &.md-6 {width: 50%;}
            &.md-7 {width: 58.33%;}
            &.md-8 {width: 66.66%;}
            &.md-9 {width: 75%;}
            &.md-10 {width: 83.33%;}
            &.md-11 {width: 91.66%;}
            &.md-12 {width: 100%;}
        }
    }
}

@media(max-width: 767px){
    .row{
        // margin: 0;
        // flex-direction: column;
        [class*="col-"] {
            width: 100%;
            &[class*="md-"] {
                width: 100%;
            }
            &.sm-1 {width: 8.33%;}
            &.sm-2 {width: 16.66%;}
            &.sm-3 {width: 25%;}
            &.sm-4 {width: 33.33%;}
            &.sm-5 {width: 41.66%;}
            &.sm-6 {width: 50%;}
            &.sm-7 {width: 58.33%;}
            &.sm-8 {width: 66.66%;}
            &.sm-9 {width: 75%;}
            &.sm-10 {width: 83.33%;}
            &.sm-11 {width: 91.66%;}
            &.sm-12 {width: 100%;}
        }
    }
}

 



} // @mixin grid-mixin($theme) Ends