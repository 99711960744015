@mixin charts-mixin() {
    .chart-header{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        .item{
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: .6rem;
            i{
                width: 1.3rem;
                height: 1.3rem;
                border-radius: .3rem;
                margin-right: .8rem;
            }
        }
    }
} // @mixin charts-mixin($theme) Ends