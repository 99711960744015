@use '@angular/material' as mat;
@mixin component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  .router-link-active {
    background: mat.get-color-from-palette($primary, .1);
    border-right: 4px solid mat.get-color-from-palette($primary);
    opacity: 1 !important;
    font-weight: 500 !important;
    color: mat.get-color-from-palette($primary);
      .mat-list-item-content{
        color: mat.get-color-from-palette($primary);
      }
  }
  .back-btn {
    margin: 0 1.5rem 0 0;
    width: 4.5rem;
    height: 4.5rem;
    ::ng-deep {
      .mat-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .inner-wrapper {
    background: mat.get-color-from-palette($foreground, slider-off, 0.03);
    padding: 15px 20px;
    border-radius: 5px;
    max-width: 80%;
    margin: 0 auto 15px;
    position: relative;
    .mat-progress-bar {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .mat-paginator {
    .mat-paginator-range-label,
    .mat-paginator-page-size-label {
      white-space: nowrap;
    }
    .mat-form-field {
      width: auto;
    }
  }
  .mat-form-field {
    width: 100%;
    font-size: 1.4rem;
    margin-bottom: 6px;
    .mat-error {
      margin: 7px 0 0 -10px;
      opacity: 0.8;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    //   border: 1px solid green;
    -webkit-text-fill-color: mat.get-color-from-palette($foreground, text);
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  @media (max-width: 950px) {
    .inner-wrapper {
      max-width: 100%;
    }
  }

  // Utilities

  .country-list-button {
    color: mat.get-color-from-palette($foreground, text) !important;
  }
  .country-selector {
    color: mat.get-color-from-palette($foreground, text) !important;
  }

  .imgUpload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.4rem;
    line-height: 1.8;
    max-width: 250px;
    margin-bottom: 2rem;
    position: relative;
    height: 140px;
    &.outline {
      border-style: dashed;
    }
    .innerBox {
      width: 100%;
      height: 100%;
    }
    .text {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      .mat-icon {
        margin-right: 0.5rem;
      }
    }
    figure {
      border-radius: 1rem;
      height: 140px;
      text-align: center;
      img {
        border-radius: 1rem;
        height: 100%;
        width: auto;
        min-width: unset;
      }
    }
    &.active {
      border: none;
      .text {
        flex-direction: column;
        opacity: 0;
        transition: 0.3s ease all;
      }
      .remove {
        position: absolute;
        right: -25px;
        top: -25px;
        z-index: 1;
      }
      figure {
        position: relative;
        overflow: hidden;
        &::before {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          opacity: 0;
          transition: 0.3s ease all;
          box-shadow: 0 0 82px rgb(0 0 0 / 70%) inset;
        }
      }
      &:hover {
        .text {
          opacity: 1;
        }
        figure {
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }
  .file-upload-block {
    max-width: 250px;
    margin-top: 1rem;
    .preview {
      position: relative;
      margin-bottom: 2rem;
      .remove {
        position: absolute;
        top: -1.5rem;
        right: -1.5rem;
      }
      figure {
        border-radius: 1rem;
        height: 140px;
        text-align: center;
        img {
          border-radius: 1rem;
          height: 100%;
          width: auto;
          min-width: unset;
        }
      }
    }
    .upload-box {
      border-style: dashed;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      cursor: pointer;
      font-size: 1.4rem;
      opacity: 0.7;
      line-height: 1.8;
      padding: 3rem;
      &.change {
        flex-direction: row;
        padding: 0.5rem;
        .mat-icon {
          margin-right: 0.5rem;
        }
      }
    }
  }
}
