@use '@angular/material' as mat;
@import "./colors";

@mixin utility-mixin($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  /*
1.) Global Links, Utilities & Buttons
-------------------------------------*/
  * {
    box-sizing: border-box;
  }

  a {
    transition: 0.3s ease all;
    text-decoration: none;
  }

  .mat-stroked-button:not([disabled]) {
    border-color: inherit;
  }

  .mat-button-toggle-checked {
    background-color: mat.get-color-from-palette($primary);
    color: mat.get-color-from-palette($foreground, text);
  }

  .f-btn {
    border-radius: 0.5rem;
    margin-left: 1rem;
    padding: .8rem 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .mat-icon {
      width: 2.8rem;
      height: 2.8rem;
      font-size: 2.8rem;

      &.drop {
        width: 3rem;
        height: 3rem;
        font-size: 3rem;
      }
    }

    span {
      font-size: 1.8rem;
      margin: 0 1rem;
      font-weight: 500;

      small {
        font-size: 0.7em;
        font-weight: 400;
        opacity: .5;
        display: block;
      }
    }
  }

  .currSymbol {
    opacity: .5;
    position: relative;
    top: -4px;
    left: -4px;
    font-size: 0.8em;
    font-weight: 400;
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .spin {
    .mat-icon {
      animation: spin .4s ease-in-out 1;
    }
  }

  /*
2.) Colors
-------------------------------------*/

  // Foregrounds
  .foreground {
    color: mat.get-color-from-palette($foreground, text);
  }

  .foreground-dark {
    color: mat.get-color-from-palette($background, background);
  }

  .foreground-20 {
    color: mat.get-color-from-palette($foreground, text, 0.2);
  }

  .primary {
    color: mat.get-color-from-palette($primary);
  }

  .accent {
    color: mat.get-color-from-palette($accent);
  }

  .warn {
    color: mat.get-color-from-palette($warn);
  }

  .green {
    color: #03bc6d;
  }

  a {
    color: mat.get-color-from-palette($primary);
  }

  .color-red {
    color: #fe3451;
  }

  .color-green {
    color: #02be6e;
  }

  .color-blue {
    color: #2962ff;
  }

  .color-orange {
    color: #ffd400;
  }

  .color-violet {
    color: #c121dc;
  }

  .color-aqua {
    color: #28dcd6;
  }

  .bg-red {
    color: #fe3451;
  }

  .bg-green {
    background: #02be6e;
  }

  .blue {
    color: $blue
  }

  .red {
    color: $red
  }

  .green {
    color: $green
  }

  .orange {
    color: $orange
  }

  .violet {
    color: $violet
  }

  .brown {
    color: $brown
  }

  .light-blue {
    color: $light-blue
  }

  .gray {
    color: $gray
  }

  // Backgrounds
  .bg-transparent {
    background: transparent !important;
  }

  .background {
    background: mat.get-color-from-palette($background, background);
  }

  .background-20 {
    background: mat.get-color-from-palette($background, background, 0.1);
  }

  .bg-primary {
    background: mat.get-color-from-palette($primary);
  }

  .bg-invert {
    background: mat.get-color-from-palette($foreground, text);
  }

  .bg-primary-normal {
    background: mat.get-color-from-palette($primary);
  }

  .bg-primary-15 {
    background: mat.get-color-from-palette($primary, 0.15);
  }

  .bg-accent {
    background: mat.get-color-from-palette($accent);
  }

  .bg-accent-15 {
    background: mat.get-color-from-palette($accent, 0.15);
  }

  .bg-card {
    background: mat.get-color-from-palette($background, card);
  }

  .bg-hover {
    background: mat.get-color-from-palette($background, hover);
  }

  .bg-status {
    background: mat.get-color-from-palette($background, status-bar);
  }

  .hover:hover {
    background: mat.get-color-from-palette($background, hover);
  }

  .hover2 {
    background: transparent;

    &:hover {
      background: mat.get-color-from-palette($background, hover);
    }
  }

  .color-app {
    color: mat.get-color-from-palette($background, hover);
  }

  /*
5.) Loops
-------------------------------------*/
  @for $i from 0 through 9 {
    .opacity-#{$i*1} {
      opacity: #{$i * 0.1};
    }

    .h-#{$i*1} {
      height: #{$i * 10}rem;
    }

    .background-#{$i*1} {
      background: mat.get-color-from-palette($background, background, $i * 0.1);
    }

    .bg-card-#{$i*1} {
      background: mat.get-color-from-palette($background, card, $i * 0.1);
    }

    .bg-hover-#{$i*1} {
      background: mat.get-color-from-palette($background, hover, $i * 0.1);
    }

    .bg-status-#{$i*1} {
      background: mat.get-color-from-palette($background, status-bar, $i * 0.1);
    }

    .bg-primary-#{$i*1} {
      background: mat.get-color-from-palette($primary, $i * 0.1);
    }

    .bg-accent-#{$i*1} {
      background: mat.get-color-from-palette($accent, $i * 0.1);
    }

    .bg-warn-#{$i*1} {
      background: mat.get-color-from-palette($warn, $i * 0.1);
    }

    .bg-invert-#{$i*1} {
      background: mat.get-color-from-palette($foreground, text, $i * 0.1);
    }

    .outline-#{$i*1} {
      border: 1px solid mat.get-color-from-palette($foreground, slider-off, $i * 0.1);
    }

    .outline-top-#{$i*1} {
      border-top: 1px solid mat.get-color-from-palette($foreground, slider-off, $i * 0.1);
    }

    .outline-bottom-#{$i*1} {
      border-bottom: 1px solid mat.get-color-from-palette($foreground, slider-off, $i * 0.1);
    }

    .outline-left-#{$i*1} {
      border-left: 1px solid mat.get-color-from-palette($foreground, slider-off, $i * 0.1);
    }

    .outline-right-#{$i*1} {
      border-right: 1px solid mat.get-color-from-palette($foreground, slider-off, $i * 0.1);
    }

    .outline-primary-#{$i*1} {
      border-color: mat.get-color-from-palette($primary, $i * 0.1);
    }

    .outline-accent-#{$i*1} {
      border-color: mat.get-color-from-palette($accent, $i * 0.1);
    }

    .outline-warn-#{$i*1} {
      border-color: mat.get-color-from-palette($warn, $i * 0.1);
    }

    .foreground-#{$i*1} {
      color: mat.get-color-from-palette($foreground, text, $i * 0.1);
    }
  }

  /*
6.) Global Utilities
-------------------------------------*/
  .wrapper {
    padding: 10px;
  }

  img {
    max-width: 100%;
  }

  .opacity {
    opacity: 0.8;
  }

  .opacity-50 {
    opacity: 0.5;
  }

  .rounded {
    border-radius: 4px;
  }

  figure {
    padding: 0;
    margin: 0;
  }

  .no-scroll {
    overflow: hidden;
    height: 100%;
  }

  .spacer {
    flex: 1 1 auto;
  }

  .flex {
    display: flex;
  }

  .flex-space-between {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .dblock {
    display: block;
  }

  .shadow {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }

  .no-shadow {
    box-shadow: 0 0 transparent !important;
  }

  .radius,
  .rounded {
    border-radius: 5px;
  }

  .outline {
    border: 1px solid mat.get-color-from-palette($foreground, slider-off);
  }

  .outline-db {
    border: 1px solid mat.get-color-from-palette($background, disabled-button);
  }

  .outline-top {
    border-top: 1px solid mat.get-color-from-palette($foreground, slider-off);
  }

  .outline-primary {
    border-color: mat.get-color-from-palette($primary);
  }

  .outline-accent {
    border-color: mat.get-color-from-palette($accent);
  }

  .dash-box {
    border: 1px solid mat.get-color-from-palette($foreground, slider-off);
    border-style: dashed;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 16rem;
    font-size: 1.3rem;
    border-radius: 8px;
    flex-direction: column;
    padding: 1.5rem 0;

    .txt {
      opacity: 0.5;
      margin-bottom: 1rem;
    }

    &.no-data {
      display: flex;
      padding: 2rem 0;

      img {
        margin: 5px 0 25px;
        opacity: .8;
      }

      lottie-player {
        max-width: 25rem;
      }
    }
  }

  .inpFld {
    background: mat.get-color-from-palette($background, card);
    border: 1px solid mat.get-color-from-palette($foreground, slider-off, 0.3);
    outline: none;
    font-size: 1.3rem;

    &:focus-visible {
      border: 1px solid mat.get-color-from-palette($foreground, text, 0.5);
    }
  }

  .flat-select {
    background: mat.get-color-from-palette($background, card);
    border: 1px solid mat.get-color-from-palette($foreground, slider-off, 0.3);
    outline: none;
    border-radius: 3px;
    padding: 5px 6px;
    font-size: 1.3rem;

    .mat-select-value-text {
      color: mat.get-color-from-palette($foreground, text, 0.5);
    }

    &:focus-visible {
      border: 1px solid mat.get-color-from-palette($foreground, text, 0.5);
    }
  }


  .line-through {
    text-decoration: line-through;
  }

  /* hide scrolling in apple devices */

  html.hideScroll {
    overflow: hidden;
    height: 100%;
    position: relative;
    -webkit-overflow-scrolling: touch;
  }

  .hideScroll body {
    overflow: hidden;
    height: 100%;
    position: relative;
  }

  .hiding {
    -webkit-animation: 0.5s ease-in-out fadeOut;
    animation: 0.5s ease-in-out fadeOut;
  }

  .showing {
    opacity: 1;
    -webkit-animation: 0.5s ease-in-out fadeIn;
    animation: 0.5s ease-in-out fadeIn;
  }

  .dnone {
    display: none;
  }

  .vHidden {
    visibility: hidden;
  }

  .desaturate {
    filter: saturate(0);
  }

  .grayScale {
    color: #949292 !important;
  }

  .relative {
    position: relative;
  }

  button {
    min-height: 4rem;
    cursor: pointer;

    .mat-icon {
      // margin-left: -5px;
    }

    .mat-spinner {
      position: absolute;
      top: calc(50% - 10px);
      left: calc(50% - 10px);
    }

    &.op-btn {
      opacity: 0.5;
      transition: 0.3s ease all;

      &:hover {
        opacity: 1;
      }
    }

    &.il {
      .mat-icon {
        margin-left: -0.5rem;
        font-size: 1.9rem;
        height: 2rem;
        width: 1.9rem;
      }
    }

    &.ir {
      .mat-icon {
        margin-right: -0.5rem;
        font-size: 1.9rem;
        height: 2rem;
        width: 1.9rem;
      }
    }
  }

  .full-width,
  .full-width img {
    width: 100%;
  }

  .full-height {
    min-height: 100vh;
  }

  .w-80 {
    width: 80% !important;
  }

  .pointer-events-none {
    pointer-events: none;
  }

  .pointer {
    cursor: pointer;
  }

  .align-left {
    text-align: left;
  }

  .align-center {
    text-align: center;
  }

  .align-right {
    text-align: right;
  }

  .strong,
  .bold {
    font-weight: 600;
  }

  .mh-100 {
    min-height: 100%;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .mat-hint {
    padding-top: 10px;
  }

  .mat-chip {
    &.blue {
      background: rgba(66, 133, 244, 0.22);
      pointer-events: none;
      color: #4285f4;
    }

    &.red {
      color: #fe3451;
      background: rgba(254, 52, 82, 0.22);
      pointer-events: none;
    }

    &.green {
      color: #02be6e;
      background: rgba(2, 190, 109, 0.22);
      pointer-events: none;
    }

    &.orange {
      color: #ffd400;
      background: rgba(255, 213, 0, 0.22);
      pointer-events: none;
    }

    &.violet {
      color: #c121dc;
      background: rgba(192, 33, 220, 0.22);
      pointer-events: none;
    }

    &.small {
      padding: 10px 6px;
      border-radius: 5px;
      min-height: unset;
      font-size: 12px;
      // opacity: .8;
    }
  }

  .inactiveBadge {
    opacity: 0.5;
    position: absolute;
    display: block;
    height: 24px;
    width: 67px;
    background: #ff1744;
    top: 50%;
    right: 10px;
    border-radius: 24px;
    transform: translateY(-50%);

    &::before {
      color: #fff;
      content: "Inactive";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 13px;
      font-weight: 500;
    }
  }



  .invalidBadge {
    position: absolute;
    padding: 0 6px;
    height: 19px;
    width: auto;
    border: 1.5px solid red;
    color: red;
    top: 50%;
    right: 10px;
    border-radius: 5px;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    font-size: 12px;
    align-items: center;
  }

  .expendForm {
    max-height: 55px;
    overflow: hidden;
    transition: 0.7s ease max-height, 0.7s ease all;
    opacity: 0.5;
    cursor: text;
    border: 1px solid mat.get-color-from-palette($foreground, base, 0.2);
    box-shadow: 0 0 30px rgb(0 0 0 / 21%) inset, 1px 1px 4px rgb(0 0 0 / 21%);

    // box-shadow: 0 0 30px rgba(0, 0, 0, 0.21) inset, 1px 1px 4px rgba(0, 0, 0, 0.21);
    &>*:not(h4) {
      opacity: 0;
      transition: 0.3s ease all;
    }

    &.open {
      cursor: default;
      opacity: 1;
      border: 0;
      border-radius: 8px;
      max-height: 1000px;
      box-shadow: none;

      &>*:not(h4) {
        opacity: 1;
      }
    }
  }

  .filter-row {
    align-items: center;

    .mat-form-field {
      font-size: 1.3rem;
    }

    .mat-stroked-button {
      font-size: 1.5rem;
      min-height: 4.6rem;

      .mat-icon {
        font-size: 2rem;
        height: 2.2rem;
        width: 2.2rem;
      }
    }
  }


  // Alerts (Starts)
  .alert {
    display: flex !important;
    margin-bottom: 1.5rem;
    padding: 1rem .8rem !important;

    .mat-icon {
      font-size: 2rem;
      height: 2rem;
      width: 2rem;
      margin: .5rem 0.5rem 0.5rem 0;
    }

    .txt {
      font-size: 1.3rem;
      line-height: 1.4;
    }

    a {
      color: inherit;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    strong {
      font-weight: 500;
    }

    &.blue {
      @include colorBorderBox($blue, 0.1, dashed);
    }

    &.red {
      @include colorBorderBox($red, 0.1, dashed);
    }

    &.green {
      @include colorBorderBox($green, 0.1, dashed);
    }

    &.orange {
      @include colorBorderBox($orange, 0.1, dashed);
    }

    &.violet {
      @include colorBorderBox($violet, 0.1, dashed);
    }
  }

  // Alerts (Ends)

  /* Custom Scrollbar*/

  .custom-scrollbar {
    overflow: auto;

    // padding-bottom: 8px;
    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
      left: -10px;
      position: relative;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 0;
    }

    &.card-scroll {
      &::-webkit-scrollbar-track {
        background: mat.get-color-from-palette($background, status-bar, 0.2);
        border-radius: 0;
      }
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background: mat.get-color-from-palette($background, status-bar, 0.5);
      }

      &::-webkit-scrollbar-track {
        background: mat.get-color-from-palette($background, card, 0.1);
      }
    }

  }


  .mat-expansion-indicator::after {
    // color: mat.get-color-from-palette($primary, 200);
    // color: mat-color($accent, 200);
    opacity: .5;
  }

  .errorHighlighting {
    // animation: highlightLoop .5s ease infinite backwards;
    animation: 1.3s ease-out infinite backwards errorHighlightLoop;
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes errorHighlightLoop {

    0%,
    100% {
      box-shadow: 0 0 40px rgba(254, 52, 82, 0.1);
    }

    50% {
      box-shadow: 0 0 40px #fe3451;
    }
  }

  .mat-dialog-container {
    // max-height: 80vh;
  }

  .mat-dialog-header {
    position: relative;

    .close {
      position: absolute;
      top: -15px;
      right: 0;
    }
  }

  .track {
    display: flex;
    text-align: center;
    padding: 15px 0;

    .col {
      width: 20%;
      opacity: 0.4;
      position: relative;

      i {
        border: 2px solid mat.get-color-from-palette($background, text, 0.2);
        display: flex;
        border-radius: 50%;
        width: 55px;
        height: 55px;
        padding: 8px;
        position: relative;
        margin: 0 auto 8px;
        justify-content: center;
        align-items: center;
      }

      span {
        display: block;
        font-weight: 500;
        font-size: 14px;
        opacity: 0.8;
      }

      small {
        display: block;
        opacity: 0.5;
        line-height: 1;
      }

      &.done {
        opacity: 1;

        i {
          border-color: #3bca5c;
          background: #c0ffcf;

          img {
            opacity: 0.4;
          }

          &::before {
            content: "";
            position: absolute;
            z-index: 1;
            width: 15px;
            height: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            left: 18px;
            top: 20px;
            border-left: 3px solid #3bca5c;
            border-bottom: 3px solid #3bca5c;
            transform: rotate(-45deg);
          }
        }

        &:not(:last-child)::before {
          content: "";
          width: 100%;
          height: 0.25rem;
          background: #3bca5c;
          position: absolute;
          left: 64px;
          top: 2.5rem;
        }
      }

      &.active {
        opacity: 1;

        i {
          background: #f4efed;
        }
      }
    }
  }

  .bottom-link {
    border-bottom: 2px solid mat.get-color-from-palette($primary);
  }

  .breadcrumb {
    display: flex;
    align-items: center;
    opacity: 1;
    letter-spacing: 0.1rem;

    a {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    mat-icon {
      font-size: 1.8rem;
      width: 1.8rem;
      height: 1.8rem;
      margin: 0 0.5rem;
      opacity: .6;
    }

    span {
      opacity: .6;
    }
  }

  .inner-banner {
    border-radius: 15px;
    // background-image: url(/assets/images/banner-bg/bg1.jpg);
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-size: cover;
    padding: 0 40px;
    margin: 0 10px 40px;
    display: flex;
    flex-wrap: wrap;
    color: #1f1f1f;
    position: relative;

    .breadcrumb {
      font-size: .9em;
      margin-left: 0.5rem;

      a {
        color: mat.get-color-from-palette($background, background);
      }

      mat-icon {
        opacity: .8;
      }

      span {
        opacity: .8;
      }
    }

    .back-btn {
      margin: 50px 1.5rem 0 0;
      width: 5.5rem;
      height: 5.5rem;

      .mat-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .mat-icon {
          font-size: 36px;
          width: 36px;
        }
      }
    }

    h1 {
      font-size: 6.4rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 2.2rem;
      margin-bottom: 2.5rem;
    }

    .btn {
      border-radius: 10px;
      font-size: 2rem;
      font-weight: 400;
      padding: .5rem 1.5rem;

      &.mat-flat-button {
        background: #1f1f1f;
        color: #fff;

      }

      .mat-icon {
        font-size: 2rem;
        width: 2rem;
        height: 2rem;
        // display: none;
      }
    }

    .left {
      width: 50%;
      padding: 40px 0;
    }

    .right {
      padding-bottom: 30px;
      width: 50%;
      margin-left: auto;
    }
    .store-wrapper{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-bottom: 30px;
      width: 50%;
      margin-left: auto;
    }

    .store-filter {
      margin-top: 2rem;
      box-shadow: 0 4px 46px rgb(0 0 0 / 10%);
      background: rgb(255 255 255 / 75%);
    }
  }

  .warning-note {
    background: #292929;
    padding: 20px;
    border-radius: 10px;
    font-weight: 600;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      width: 140px;
      height: 90px;
      border-radius: 50%;
      background: #ffcc00;
      position: absolute;
      left: -40px;
      z-index: 1;
      top: -10px;
      filter: blur(56px);
    }

    .icon {
      font-size: 18px;
      height: 18px;
      width: 18px;
      margin-top: 2px;
      margin-right: 6px;
      color: #ffcc00;
    }

    .message {
      line-height: 1.3;
      color: whitesmoke;
    }
  }

  @media (max-width: 767px) {
    .custom-scrollbar {
      -webkit-overflow-scrolling: touch;
    }

    .custom-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .custom-scrollbar::-webkit-scrollbar-thumb,
    .custom-scrollbar::-webkit-scrollbar-track {
      display: none;
    }

    .custom-scrollbar::-webkit-scrollbar {
      position: static;
    }

    .filter-row {
      &.row [class*="col-"] {
        padding: 0 10px;
      }
    }

    .f-btn {
      padding: 0.4rem .2rem 0.4rem .6rem;

      &.af-filter {
        padding-right: .6rem;
      }

      .mat-icon {
        &.drop {
          width: 2rem;
          height: 2rem;
          font-size: 2rem;
        }
      }

      span {
        // display: none;
      }
    }

    .inner-banner {
      margin: 15px 10px 10px;
      padding: 0 20px;

      h1 {
        font-size: 3.4rem;
      }

      p {
        font-size: 1.6rem;
        margin-bottom: 1rem;
      }

      .btn {
        border-radius: 8px;
        font-size: 1.6rem;
        padding: 0 1.2rem;
        min-height: unset;
      }

      .left {
        width: 100%;
        padding: 20px 0;
      }

      .back-btn {
        margin-top: 20px;
        width: 4rem;
        height: 4rem;
        margin-right: 0.5rem;

        .mat-button-wrapper {
          .mat-icon {
            font-size: 22px;
            width: 22px;
          }
        }

        &+.left {
          width: calc(100% - 45px);
        }
      }

      .right {
        width: 100%;
        padding-bottom: 10px;
      }

      
      
    }
  }
}

// @mixin utility-mixin($theme) Ends