@use '@angular/material' as mat;
@mixin editable-field-mixin($theme) {
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);

  .editable-field{
    width: 100%;
    background: transparent;
    border: 2px solid transparent;
    outline: none;
    padding: 9px 10px;
    border-radius: 5px;
    font-size: 14px;
    opacity: .8;
    color: inherit;
    transition: .3s ease all;
    &:hover{
      background: mat.get-color-from-palette($background, hover);
      opacity: 1;
    }
    &:focus{
      background: mat.get-color-from-palette($background, hover);
      border-color: mat.get-color-from-palette($primary);
      opacity: 1;
    }
    &.saved{
        &:focus{
            background: mat.get-color-from-palette($background, focused-button);
            border-color: mat.get-color-from-palette($background, disabled-button);
        }
    }
  }

  .dark-field{
    .editable-field{
      background: mat.get-color-from-palette($background, card, .5);
    }
  }

} // @mixin expansion-panel Ends