@use "sass:math";
@mixin material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
  
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
  
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
  
    /* Support for IE. */
    font-feature-settings: 'liga';
  }
  
  @mixin display-flex($align: center, $justify: center) {
    display: flex;
    align-items: $align;
    justify-content: $justify;
  }

  @mixin circle($size, $bg: #fff) {
    width: $size;
    height: $size;
    background-color: $bg;
    -webkit-border-radius: math.div($size, 2);
    -moz-border-radius: math.div($size, 2);
    border-radius: math.div($size, 2);
  }
  @mixin font-size($size, $line-height, $weight) {
    font-size: #{$size}px;
    line-height: #{$line-height}px;
    font-weight: $weight;
  }








//   @mixin font-size($size, $line-height) {
//     font-size: #{$size}px;
//     line-height: #{$line-height}px;
//   }
  
  
  
//   @mixin content-size($width, $height) {
//     width: #{$width}px;
//     height: #{$height}px;
//   }
  
 
  
//   @mixin box-style {
//     border-radius: 8px;
//     box-shadow: 0 2px 8px 0 rgba(26, 24, 30, 0.04);
//     background-color: #ffffff;
//   }
  
//   @mixin spacing($space) {
//     .pl#{$space} {
//       padding-left: #{$space}px;
//     }
//     .ml#{$space} {
//       margin-left: #{$space}px;
//     }
//     .pr#{$space} {
//       padding-right: #{$space}px;
//     }
//     .mr#{$space} {
//       margin-right: #{$space}px !important;
//     }
//     .pt#{$space} {
//       padding-top: #{$space}px;
//     }
//     .mt#{$space} {
//       margin-top: #{$space}px;
//     }
//     .pb#{$space} {
//       padding-bottom: #{$space}px;
//     }
//     .mb#{$space} {
//       margin-bottom: #{$space}px;
//     }
//     .my#{$space} {
//       margin-top: #{$space}px;
//       margin-bottom: #{$space}px;
//     }
//     .mx#{$space} {
//       margin-left: #{$space}px;
//       margin-right: #{$space}px;
//     }
//     .py#{$space} {
//       padding-top: #{$space}px;
//       padding-bottom: #{$space}px;
//     }
//     .px#{$space} {
//       padding-left: #{$space}px;
//       padding-right: #{$space}px;
//     }
//     .p#{$space} {
//       padding: #{$space}px;
//     }
//     .m#{$space} {
//       margin: #{$space}px;
//     }
//     .-m#{$space} {
//       margin: -#{$space}px;
//     }
//   }
  
//   @mixin alert-style($color) {
//     color: $color;
//     background-color: rgba($color, 0.1);
//     padding: 10px 16px;
//     border-radius: 3px;
//     @include font-size(14, 22);
//   }
  
//   @mixin anchor-link($color, $hoverColor) {
//     color: $color;
//     cursor: pointer;
//     overflow: hidden;
//     &.underline {
//       text-decoration: underline;
//     }
//     &:hover {
//       color: $hoverColor;
//       &.underline {
//         text-decoration: underline;
//       }
//     }
//   }
  
//   @mixin split-color-bg($color1, $color1size, $color2, $color2size, $angle ){
//     background: -webkit-linear-gradient($angle, $color1 $color1size, $color2 $color2size);
//     background: -o-linear-gradient($angle, $color1 $color1size, $color2 $color2size);
//     background: -moz-linear-gradient($angle, $color1 $color1size, $color2 $color2size);
//     background: linear-gradient($angle, $color1 $color1size, $color2 $color2size);
//   }
  